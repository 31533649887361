import React from "react";
import {
  Create,
  TextInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  PasswordInput,
} from "react-admin";
import Validator from "../Validator";

const ValidatorWithEntity = Validator.bind(null, 'user');

const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm validate={ValidatorWithEntity}>
      <TextInput source="email" required />
      <TextInput source="name" required />
      <ReferenceInput label="Country" source="country_id" reference="countries" required>
          <SelectInput optionText="name" />
      </ReferenceInput>
      <PasswordInput source="password" required />
    </SimpleForm>
  </Create>
);

export default UserCreate;
