import React from "react";
import {
  Create,
  TextInput,
  SimpleForm,
  Toolbar,
  SaveButton,
} from "react-admin";
import { parse } from 'query-string';

const InvoiceCreate = props => {
    const { invoice_selection: invoice_selection_string } = parse(props.location.search);
    const invoiceIdSelection = invoice_selection_string ? invoice_selection_string : null;
    
    const selectionList = invoiceIdSelection
      ? invoiceIdSelection.split(',').map(id => parseInt(id, 10))
      : null;
  
      const InvoiceCreateToolbar = props => (
        <Toolbar {...props}>
            <SaveButton
                label="Post"
                transform={data => ({ ...data, 'invoiceSelection': selectionList})}
                submitOnEnter={true}
            />
        </Toolbar>
    );
  
    return (
      <Create {...props}>
        <SimpleForm toolbar={<InvoiceCreateToolbar/>}>
            <TextInput label="Email" source="email" />
        </SimpleForm>
      </Create>
    );
  };

export default InvoiceCreate;