import React from "react";
import {
  Edit,
  TextInput,
  SimpleForm,
} from "react-admin";
import Validator from "../Validator";

const ValidatorWithEntity = Validator.bind(null, 'notificationMessage');

const MessagesEdit = (props) => (
  <Edit {...props}>
    <SimpleForm validate={ValidatorWithEntity}>
        <TextInput multiline label="Message (English)" source="message_en" />
        <TextInput multiline label="Message (Estonian)" source="message_et" />
        <TextInput multiline label="Message (Latvian)" source="message_lv" />
        <TextInput multiline label="Message (Russian)" source="message_ru" />
    </SimpleForm>
  </Edit>
);

export default MessagesEdit;