import * as React from 'react';
import { FC } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardHeader, CardContent } from '@material-ui/core';

interface DateSelection {
    startDate: Date
    endDate: Date
}

interface Props {
    dateSelection: DateSelection;
    dateSelectionChange: any;
}



const Header: FC<Props> = ({ dateSelection, dateSelectionChange }) => {
    return (
        <Card>
            <CardHeader title="Date range" />
            <CardContent>
                <div style={{ width: '100%', height: '100%' }}>
                    <div style={{display: 'inline-block'}}>
                    <label>Date from: </label>
                        <DatePicker
                            selected={dateSelection.startDate}                            
                            onChange={(date: Date) => {
                                dateSelection.startDate = date
                                dateSelectionChange(dateSelection);
                            }}
                        />
                    </div>
                    
                    <div style={{display: 'inline-block', marginLeft: '3rem'}}>
                    <label>Date to: </label>
                        <DatePicker 
                            selected={dateSelection.endDate}
                            onChange={(date: Date) => {
                                dateSelection.endDate = date
                                dateSelectionChange(dateSelection);
                            }}
                        />
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default Header