import React from "react";
import { Fragment } from "react";
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  DateField,
  Pagination,
  TextInput,
  Filter,
  Responsive,
  TopToolbar,
} from "react-admin";
import withStyles from "@material-ui/core/styles/withStyles";
import Divider from "@material-ui/core/Divider";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import FilterSidebar from "../filters/CountryStatusFilter";
import SendInvoiceButton from "./SendInvoiceButton";
import { ImportButton } from "react-admin-import-csv";
import config from "./ImportConfig";

const InvoicesPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const PostBulkActionButtons = (props) => (
  <Fragment>
    <SendInvoiceButton label="Send invoice" {...props} />
  </Fragment>
);

const ListActions = (props) => {
  const { className } = props;
  return (
    <TopToolbar className={className}>
      <ImportButton label="Import B2B Orders" {...props} {...config} />
    </TopToolbar>
  );
};

const ReportFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Order Id" source="order_group_id" resettable alwaysOn />
    <TextInput label="Address" source="address" resettable alwaysOn />
    <TextInput label="Client name" source="client_name" />
    <TextInput label="Client phone" source="client_phone" />
    <TextInput label="Delivered at" source="delivered_at" />
    <TextInput label="Delivery begin" source="delivery_begin" />
    <TextInput label="Delivery end" source="delivery_end" />
    <TextInput label="Gift text" source="gift_text" />
    <TextInput label="Order price" source="price" />
    <TextInput label="Order description" source="description" />
    <TextInput label="Payment method" source="payment_method" />
    <TextInput label="Product code" source="code" />
    <TextInput label="Product description (english)" source="description_en" />
    <TextInput label="Invoice email" source="invoice_email" />
  </Filter>
);

const datagridStyles = {
  total: { fontWeight: "bold" },
};

class TabbedInvoicesList extends React.Component {
  constructor(props) {
    super(props);
    const { filterValues, setFilters } = props;
    setFilters({ ...filterValues, type: "regular" });
  }
  tabs = [
    { id: "regular", name: "Web Store" },
    { id: "business", name: "Business to business" },
  ];

  handleChange = (event, value) => {
    const { filterValues, setFilters } = this.props;
    setFilters({ ...filterValues, type: value });
  };

  render() {
    const { classes, filterValues, ...props } = this.props;
    return (
      <Fragment>
        <Tabs
          centered
          value={filterValues.type}
          indicatorColor="primary"
          onChange={this.handleChange}
        >
          {this.tabs.map((choice) => (
            <Tab key={choice.id} label={choice.name} value={choice.id} />
          ))}
        </Tabs>
        <Divider />
        <Responsive
          medium={
            <div>
              {filterValues.type === "regular" && (
                <Datagrid {...props}>
                  <TextField label="Invoice Id" source="id" />
                  <DateField label="Created At" source="created_at" />
                  <TextField label="Payment status" source="status" />
                  <TextField label="Payment Method" source="payment_method" />
                  <TextField label="Price" source="price_total" />
                  <TextField lavel="Country" source="country" />
                  <ShowButton />
                </Datagrid>
              )}
              {filterValues.type === "business" && (
                <Datagrid {...props}>
                  <TextField label="Invoice Id" source="id" />
                  <DateField label="Created At" source="created_at" />
                  <TextField label="Payment status" source="status" />
                  <TextField label="Payment Method" source="payment_method" />
                  <TextField label="Price" source="price_total" />
                  <TextField lavel="Country" source="country" />
                  <ShowButton />
                </Datagrid>
              )}
            </div>
          }
        />
      </Fragment>
    );
  }
}

const StyledTabbedInvoiceList = withStyles(datagridStyles)(TabbedInvoicesList);

const InvoicesList = ({ permissions, classes, ...props }) => (
  <List
    {...props}
    bulkActionButtons={<PostBulkActionButtons />}
    actions={<ListActions />}
    aside={<FilterSidebar />}
    filter={{ permission_id: permissions }}
    filters={<ReportFilter />}
    pagination={<InvoicesPagination />}
    hasCreate={false}
  >
    <StyledTabbedInvoiceList />
  </List>
);

export default InvoicesList;
