interface Custom {
  isValid: any;
}

interface Required {
  isRequired: boolean;
}

interface KeyValidators {
  required: Required;
  custom: Custom | null;
}

interface FieldValidators {
  [key: string]: KeyValidators;
}


const CustomValidator = (entity: string, fieldValidators: FieldValidators) => {
  if (entity === "notificationMessage") {
    const format = /[`’€@#$^&*()_+\-=\[\]{};':"\\|<>\/~]/;

    const notificationMessageFields = [
      "message_et",
      "message_en",
      "message_lv",
      "message_ru",
    ];

    notificationMessageFields.forEach((field: string) => {
      let message = "";
      const validTest = (value: string) => {
        const containsSpecialChars = format.test(value);

        const messageLengthValid = containsSpecialChars
          ? value.length < 400
          : value.length < 470;

        if (!messageLengthValid) {
          message = containsSpecialChars
            ? "The maximum length of message with special characters is 400 characters"
            : "The maximum length of message without special characters is 470 characters";
          return {
            isValid: false,
            message: message
          }
        } else {
          return {
            isValid: true,
            message: ""
          }
        }
      };

      const custom: Custom = {
        isValid: validTest,
      };

      fieldValidators[field].custom = custom;
    });
  }
  return;
};

export default CustomValidator;
