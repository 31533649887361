import * as React from 'react';
import { FC } from 'react';
import DollarIcon from '@material-ui/icons/AttachMoney';

import CardWithIcon from './CardWithIcon';

interface Props {
    value?: string;
}

const MonthlyRevenue: FC<Props> = ({ value }) => {   
    return (
        <CardWithIcon
            to="/reports"
            icon={DollarIcon}
            title="Revenue"
            subtitle={value}
        />
    );
};

export default MonthlyRevenue;