import * as React from 'react';
import { FC } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import CardWithIcon from './CardWithIcon';
import { Link } from 'react-router-dom';
import { TopMissingRegion } from '../../types';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';

interface Props {
    topMissingRegions?: TopMissingRegion[];
}

const TopMissingRegions: FC<Props> = ({ topMissingRegions = [], }) => {
    return (
        <CardWithIcon
            to="/missing_regions"
            icon={NotListedLocationIcon}            
            subtitle="Top missing regions"
        >
            <List dense={true}>
                {topMissingRegions.map(record => (
                    <ListItem
                        key={record.postal_code}
                        button
                        component={Link}
                        to={`/missing_regions/`}
                    >
                        <ListItemText
                            primary={record.postal_code}  
                            secondary="Postal code"
                        />
                        <ListItemSecondaryAction>
                            <span>
                                Count: {record.search_count}
                            </span>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </CardWithIcon>
    );
};

export default TopMissingRegions