import * as React from "react";
import { Link } from 'react-router-dom';
import {
    Button
} from 'react-admin';

const SendInvoiceButton = ({ selectedIds }) => {
    
    return (
        <Button
        component={Link}
        to={`invoices/create?invoice_selection=${selectedIds}`}
        label="Send Invoice"
        >
        </Button>
    )
};

export default SendInvoiceButton;
