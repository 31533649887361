import React from "react";
import {
  Edit,
  TextInput,
  SelectInput,
  CheckboxGroupInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  TabbedForm,
  FormTab,
  BooleanInput,
  FormDataConsumer,
  NumberInput,
  DateInput,
  NumberField,
  DateField,
} from "react-admin";
import Validator from "../Validator";
import { Grid, InputAdornment } from "@material-ui/core";
const ValidatorWithEntity = Validator.bind(null, "region");

const TimeArray = [
  { id: "0:00", name: "0:00" },
  { id: "1:00", name: "1:00" },
  { id: "2:00", name: "2:00" },
  { id: "3:00", name: "3:00" },
  { id: "4:00", name: "4:00" },
  { id: "5:00", name: "5:00" },
  { id: "6:00", name: "6:00" },
  { id: "7:00", name: "7:00" },
  { id: "8:00", name: "8:00" },
  { id: "9:00", name: "9:00" },
  { id: "10:00", name: "10:00" },
  { id: "11:00", name: "11:00" },
  { id: "12:00", name: "12:00" },
  { id: "13:00", name: "13:00" },
  { id: "14:00", name: "14:00" },
  { id: "15:00", name: "15:00" },
  { id: "16:00", name: "16:00" },
  { id: "17:00", name: "17:00" },
  { id: "18:00", name: "18:00" },
  { id: "19:00", name: "19:00" },
  { id: "20:00", name: "20:00" },
  { id: "21:00", name: "21:00" },
  { id: "22:00", name: "22:00" },
  { id: "23:00", name: "23:00" },
];

const DaysArray = [
  { id: 1, name: "Monday" },
  { id: 2, name: "Tuesday" },
  { id: 3, name: "Wednesday" },
  { id: 4, name: "Thursday" },
  { id: 5, name: "Friday" },
  { id: 6, name: "Saturday" },
  { id: 7, name: "Sunday" },
];

const RegionEdit = (props) => (
  <Edit {...props}>
    <TabbedForm validate={ValidatorWithEntity}>
      <FormTab label="details">
        <TextInput label="Name (English)" source="name_en" required />
        <TextInput label="Name (Estonian)" source="name_et" required/>
        <TextInput label="Name (Latvian)" source="name_lv" required/>
        <TextInput label="Name (Russian)" source="name_ru" required/>
        <ReferenceInput
          label="Country"
          source="country_id"
          reference="countries"
          required
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <BooleanInput
          label="Publish Region"
          source="published"
          defaultValue={true}
        />
        <CheckboxGroupInput
          required
          label="Delivery days"
          source="delivery_days"
          choices={DaysArray}
        />
      </FormTab>
      <FormTab label="delivery times">
        <ArrayInput
          label="Delivery times (ordered by start time on save)"
          source="delivery_times"
          required
        >
          <SimpleFormIterator>
            <SelectInput
              required
              label="Start time"
              source="start_time"
              choices={TimeArray}
            />
            <SelectInput
              required
              label="End time"
              source="end_time"
              choices={TimeArray}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Maximum order counts">
        <ArrayInput label="Closed delivery times (ordered by start time on save)" source="maximum_order_counts" >
          <SimpleFormIterator>
                <DateInput
                label="Target date"
                source="target_date"
              />
                <SelectInput
                  label="Start time"
                  source="start_time"
                  choices={TimeArray}
                />
                <SelectInput
                  label="End time"
                  source="end_time"
                  choices={TimeArray}
                />
                <NumberInput 
                  label="Maximum order count" 
                  source="order_count"
                />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="postal codes">
      <FormDataConsumer subscription={{ values: true }}>
          {({ formData, ...rest }) =>
            formData.type === "single" && (
              <div>
                <NumberInput
                  label="Postal code range"
                  source="postal_code_from"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">from</InputAdornment>
                    ),
                  }}
                />
                <NumberInput
                  label="Postal code range"
                  source="postal_code_to"
                  style={{marginLeft: 16 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">to</InputAdornment>
                    ),
                  }}
                />
              </div>
            )
          }
          </FormDataConsumer>
        <ArrayInput
          label="Included postal codes"
          source="included_postal_codes"
        >
          <SimpleFormIterator>
            <TextInput label="Code" source="code" />
          </SimpleFormIterator>
        </ArrayInput>
        <FormDataConsumer subscription={{ values: true }}>
          {({ formData, ...rest }) =>
            formData.type === "single" && (
              <ArrayInput
                label="Excluded postal codes"
                source="excluded_postal_codes"
                {...rest}
              >
                <SimpleFormIterator>
                  <TextInput label="Code" source="code" />
                </SimpleFormIterator>
              </ArrayInput>
            )
          }
        </FormDataConsumer>
      </FormTab>
      <FormDataConsumer subscription={{ values: true }}>
          {({ formData, ...rest }) =>
            formData.type === "combo" && (
              <FormTab label="Contained regions" {...rest}>
                <ArrayInput source="contained_regions" {...rest}>
                  <SimpleFormIterator disableAdd disableRemove>
                    <FormDataConsumer>
                      {({ formData, scopedFormData, getSource, ...rest }) => (
                        <ReferenceInput
                          label="Region"
                          source={getSource("region_id")}
                          reference="regions"
                          perPage={1000}
                          required
                        >
                          <SelectInput optionText="name_en" />
                        </ReferenceInput>
                      )}
                    </FormDataConsumer>
                  </SimpleFormIterator>
                </ArrayInput>
              </FormTab>
            )
          }
        </FormDataConsumer>
    </TabbedForm>
  </Edit>
);

export default RegionEdit;
