import Validations from "./Validations";

interface MandatoryFields {
  [key: string]: string[];
}

interface FormValue {
  [key: string]: string;
}

interface Errors {
  [key: string]: string;
}

const mandatoryFields: MandatoryFields = {
  user: ["name", "country_id", "email", "password"],
  sellingPoint: [
    "title",
    "lat",
    "lng",
    "address",
    "type",
    "country_id",
    "region_id",
  ],
  region: [
    "name_en",
    "name_lv",
    "name_et",
    "name_ru",
    "delivery_days",
    "delivery_times",
    "country_id",
    "maximum_order_counts",
  ],
  picture: ["name"],
  notificationMessage: ["message_en", "message_et", "message_lv", "message_ru"],
};

const productMandatoryFields: MandatoryFields = {
  payment_on_delivery: [
    "description_en",
    "description_et",
    "description_lv",
    "description_ru",
    "weight_from",
    "weight_to",
    "status",
    "country_id",
  ],
  payment_on_order: [
    "description_en",
    "description_et",
    "description_lv",
    "description_ru",
    "price",
    "weight",
    "status",
    "country_id",
  ],
};

const FormValidator = (entity: string, values: FormValue) => {
  const errors: Errors = {};
  const entityValidation = Validations(entity, !!values["payment_on_delivery"]);

  if (values) {
    Object.keys(values).forEach((key: string) => {
      const value = values[key];
      const fieldValidation = entityValidation[key];

      if (fieldValidation?.required?.isRequired && !value) {
        errors[key] = "This field is required!";
      }

      // Product id requirement depends of the product type
      if (entity === "product" && key === "code" && !value) {
        errors[key] = "This field is required!";
      }

      const custom = fieldValidation?.custom;
      if (custom?.isValid) {
        const valid = custom.isValid(value);
        if (!valid.isValid) {
          errors[key] = valid.message;
        }
      }
    });
  }

  // Product mandatory fields depend of the payment method
  if (entity === "product") {
    const isPaymentOnDelivery = !!values.payment_on_delivery;
    const fields = isPaymentOnDelivery
      ? productMandatoryFields["payment_on_delivery"]
      : productMandatoryFields["payment_on_order"];
    fields.forEach((field: string) => {
      if (!values[field]) {
        errors[field] = "This field is required!";
      }
    });

    // Product id requirement depends of the product type
    if (!values.is_combo && !values.code) {
      errors["code"] = "This field is required!";
    }

    if (!values.is_combo && !values.kg_price) {
      errors["kg_price"] = "This field is required!";
    }
  } else {
    mandatoryFields[entity].forEach((field: string) => {
      if (!values[field]) {
        errors[field] = "This field is required!";
      }
    });
  }

  return errors;
};

export default FormValidator;
