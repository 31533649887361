import { ImportConfig } from "react-admin-import-csv";
//import ImportErrors from "./ImportErrors";
import auth from '../../providers/auth';
interface RowItems {
    [key: string]: string;
}


const validateCsvRow = async (row: any) => {
    let promise = Promise.resolve();
    return promise;
};

const transformCsvRows = async (rows: any[]) => {
    
    const parsedRows: any[] = [];
    const permission = await auth.getPermissions();
    rows.forEach(function (row) {
        const rowItem: string[] = Object.values(row);

        const parsedRow: RowItems = {
            client_name: rowItem[0],          
            address: [rowItem[2], rowItem[3]].join(' '),
            extra_info: rowItem[4],
            gift_text: rowItem[5],
            product_name: rowItem[6],
            product_count: rowItem[7],
            delivery_date: rowItem[8],
            permission_id: permission,
        };

        // Set the delivery times
        const deliveryTimesSplit = rowItem[9].split('-');
        parsedRow['delivery_time'] = deliveryTimesSplit[0];
        parsedRow['delivery_begin'] = deliveryTimesSplit[0];
        parsedRow['delivery_end'] = deliveryTimesSplit[1];

        // Parse the phone nr
        let phoneNr = "";
        if (permission === "1") {
            phoneNr = rowItem[1].includes('372') ? phoneNr : "372" + rowItem[1];
        } else {
            phoneNr = rowItem[1].includes('371') ? phoneNr : "371" + rowItem[1];
        }

        parsedRow['client_phone'] = phoneNr;
        
        parsedRows.push(parsedRow);
    });

    return parsedRows;
};

const config: ImportConfig = {
    // * Enable logging
    logging: false,
    // * Disable "import new" button
    disableImportNew: false,
    // * Disable "import overwrite" button
    disableImportOverwrite: true,
    // * a function to handle row errors after import
    //postCommitCallback: (errors) => ImportErrors(errors),
    // * Transform rows before anything is sent to dataprovider
    transformRows: async (rows) => transformCsvRows(rows),
    // * Async function to validate a row, reject the promise if it's not valid
    validateRow: async (row) => validateCsvRow(row),
}

export default config;