import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Pagination,
} from "react-admin";


const MessagePagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />

const MessageList = props => (
    <List 
    {...props}
    pagination={<MessagePagination />}
    >
        <Datagrid>
            <TextField source="id" />
            <EditButton />
        </Datagrid>
    </List>
)


export default MessageList;