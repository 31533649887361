import React, { useState, useCallback, FC, useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Title, useDataProvider, useVersion, useMutation} from "react-admin";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { Button } from '@material-ui/core';
import './StoreOrder.css';
import auth from '../../providers/auth';

import { Product } from "../../types";

const SortableItem = SortableElement(({ value, orderNr }: { value: string, orderNr: number }) => (
  <li className="SortableItem"> (Order Nr. {orderNr}) {value}</li>
));

const SortableList = SortableContainer(({ items }: { items: Product[] }) => {
  return (
    <ul className="SotableList">
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value.description}  orderNr={index}/>
      ))}
    </ul>
  );
});

interface ProductStats {
  products: Product[];
}

interface State {
  items: Product[];
}

const StoreOrder: FC = () => {
  //const { loaded, permissions, loading: isFetching } = usePermissions();
  const [state, setState] = useState<State>({
    items: [],
  });

  const dataProvider = useDataProvider();
  const version = useVersion();

  const fetchProducts = useCallback(async (permissions) => {
    const { data: productList } = await dataProvider.getList<Product>(
      "products",
      {
        filter: {
          status: "published",
          permission_id: permissions,
          country_id: 1
        },
        sort: { field: "store_order_nr", order: "ASC" },
        pagination: { page: 1, perPage: 200 },
      }
    );
    const aggregations = productList.reduce(
      (stats: ProductStats, product) => {        
        stats.products.push(product);
        return stats;
      },
      {
        products: [],
      }
    );
    setState(() => ({
      items: aggregations.products,
    }));
  }, [dataProvider]);

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    setState({
      items: arrayMove(state.items, oldIndex, newIndex),
    });
  
  };

  const [mutate, {loading: isSaving}] = useMutation();

  const saveProductOrder = () => mutate({
    type: 'create',
    resource: 'product-store-order',
    payload: {
        data: state.items
    }
  });

  useEffect(() => {
    auth.getPermissions()
      .then((permissions) => fetchProducts(permissions))
  }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card>
      <Title title="Product store order" />
      <CardContent>
        <SortableList items={state.items} onSortEnd={onSortEnd} helperClass="SortableHelper"/>
        <Button variant="contained" color="primary" onClick={saveProductOrder} disabled={isSaving}>
            Save Product order
        </Button>
      </CardContent>
    </Card>
  );
};

export default StoreOrder;
