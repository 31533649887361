interface MandatoryFields {
  [key: string]: string[];
}

interface ProductMandatoryFields {
  [key: string]: string[];
}

const mandatoryFields: MandatoryFields = {
  user: ["name", "country_id", "email", "password"],
  sellingPoint: [
    "title",
    "lat",
    "lng",
    "address",
    "type",
    "country_id",
    "region_id",
  ],
  region: [
    "name_en",
    "name_et",
    "name_ru",
    "name_lv",
    "delivery_days",
    "delivery_times",
    "country_id",
    "maximum_order_counts",
  ],
  picture: ["name"],
  notificationMessage: ["message_en", "message_et", "message_lv", "message_ru"],
};

const productMandatoryFields: ProductMandatoryFields = {
  payment_on_delivery: [
    "description_en",
    "description_et",
    "description_lv",
    "description_ru",
    "weight_from",
    "weight_to",
    "status",
    "country_id",
  ],
  payment_on_order: [
    "description_en",
    "description_et",
    "description_lv",
    "description_ru",
    "price",
    "weight",
    "status",
    "country_id",
  ],
};

interface Custom {
  isValid: any;
}

interface Required {
  isRequired: boolean;
}

interface KeyValidators {
  required: Required;
  custom: Custom | null;
}

interface FieldValidators {
  [key: string]: KeyValidators;
}
const MandatoryFieldValidator = (
  entity: string,
  fieldValidators: FieldValidators,
  paymentOnDelivery?: boolean
) => {
  let entityFields = null;
  if (entity === "product") {
    entityFields = paymentOnDelivery
      ? productMandatoryFields["payment_on_delivery"]
      : productMandatoryFields["payment_on_order"];
  } else {
    entityFields = mandatoryFields[entity];
  }

  entityFields.forEach((field: string) => {
    const required: Required = {
      isRequired: true,
    };

    const keyValidator: KeyValidators = {
      required: required,
      custom: null,
    };

    fieldValidators[field] = keyValidator;
  });

  return;
};

export default MandatoryFieldValidator;
