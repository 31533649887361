import React from "react";
import { List, Datagrid, TextField, EditButton, Pagination, TextInput, Filter } from "react-admin";
import FilterSidebar from '../filters/CountryFilter';

const SellingPointsPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />

const SellingPointFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Address" source="address"/>
    <TextInput label="Info" source="info_en"/>
    <TextInput label="Latitude" source="lat"/>
    <TextInput label="Longitude" source="lng"/>
    <TextInput label="Opening" source="opening_en"/>
    <TextInput label="Title" source="title"/>     
    <TextInput label="Type" source="type"/>
  </Filter>
);


const SellingPointsList = ({permissions, ...props}) => (
  <List 
  {...props}
  aside={<FilterSidebar/>}
  filter={{"permission_id": permissions}}
  filters={<SellingPointFilter />}
  pagination={<SellingPointsPagination />}
  >
    <Datagrid>
      <TextField label="Title" source="title" />
      <TextField label="Country" source="country" />
      <TextField label="City" source="region" />
      <TextField label="Latitude" source="lat" />
      <TextField label="Longitude" source="lng" />
      <TextField label="Opening" source="opening" />
      <TextField label="Type" source="type" />
      <EditButton />
    </Datagrid>
  </List>
);

export default SellingPointsList;
