import * as React from "react";
import { Link } from 'react-router-dom';
import {
    Button
} from 'react-admin';

const IncludedRegionsCreationButton = ({ selectedIds }) => {
    
    return (
        <Button
        component={Link}
        to={`regions/create?region_selection=${selectedIds}`}
        label="Create Combined region"
        >
        </Button>
    )
};

export default IncludedRegionsCreationButton;