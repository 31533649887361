import * as React from "react";
import { FC } from "react";
import { Card, CardHeader, CardContent } from "@material-ui/core";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { format, addDays, subDays } from "date-fns";

import { OrderGroup } from "../../types";

interface DateSelection {
  startDate: Date;
  endDate: Date;
}

interface TotalByDay {
  date: number;
  total: number;
}

const addDay = (date: Date, day: number) => {
  date.setDate(date.getDate() + day);
  return date;
};

const getDays = (dateSelection: DateSelection): Date[] => {
  let startDate = new Date(dateSelection.startDate);
  const endDate = new Date(dateSelection.endDate);

  let dayCount = 0;
  while (startDate <= endDate) {
    startDate = addDay(startDate, 1);
    dayCount++;
  }

  return Array.from({ length: dayCount}, (_, i) => subDays(endDate, i));
};

const dateFormatter = (date: number): string =>
  new Date(date).toLocaleDateString();

const aggregateOrdersByDay = (
  orderGroups: OrderGroup[]
): { [key: string]: number } =>
  orderGroups 
    .filter((orderGroup: OrderGroup) => orderGroup.status === "COMPLETED")
    .reduce((acc, curr) => {     
      const day = format(new Date(curr.created_at), "yyyy-MM-dd");
      if (!acc[day]) {
        acc[day] = 0;
      }
      acc[day] += +curr.price_total;
      return acc;
    }, {} as { [key: string]: number });

const getRevenuePerDay = (
  orderGroups: OrderGroup[],
  dateSelection: DateSelection
): TotalByDay[] => {
  const daysWithRevenue = aggregateOrdersByDay(orderGroups);  
  const lastDays = getDays(dateSelection);  

  return lastDays.map((date) => ({
    date: date.getTime(),
    total: daysWithRevenue[format(date, "yyyy-MM-dd")] || 0,
  }));
};

const OrderChart: FC<{
  orderGroups?: OrderGroup[];
  dateSelection: DateSelection;
}> = ({ orderGroups, dateSelection }) => {
  if (!orderGroups) return null;

  return (
    <Card>
      <CardHeader title="Revenue history" />
      <CardContent>
        <div style={{ width: "100%", height: 300 }}>
          <ResponsiveContainer>
            <AreaChart data={getRevenuePerDay(orderGroups, dateSelection)}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="date"
                name="Date"
                type="number"
                scale="time"
                domain={[
                  addDays(dateSelection.startDate, 1).getTime(),
                  dateSelection.endDate.getTime(),
                ]}
                tickFormatter={dateFormatter}
              />
              <YAxis dataKey="total" name="Revenue" unit="€" />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip
                cursor={{ strokeDasharray: "3 3" }}
                /* tslint:disable-next-line */
                formatter={(value: any) =>
                  new Intl.NumberFormat(undefined, {
                    style: "currency",
                    currency: "EUR",
                  }).format(value as any)
                }
                labelFormatter={(label: any) => dateFormatter(label)}
              />
              <Area
                type="monotone"
                dataKey="total"
                stroke="#8884d8"
                strokeWidth={2}
                fill="url(#colorUv)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
};

export default OrderChart;
