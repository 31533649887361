import React from "react";
import {
  Edit,
  TextInput,
  SimpleForm,
  PasswordInput,
} from "react-admin";

import Validator from "../Validator";

const ValidatorWithEntity = Validator.bind(null, 'user');

const UserEdit = (props) => (
  <Edit {...props}>
    <SimpleForm validate={ValidatorWithEntity}>
      <TextInput source="name" />
      <TextInput source="email" />
      <PasswordInput source="password" />
    </SimpleForm>
  </Edit>
);

export default UserEdit;
