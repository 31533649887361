import React from "react";
import {
  Edit,
  TextInput,
  TabbedForm,
  ReferenceInput,
  FormTab,
  SelectInput,
  AutocompleteInput,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  NumberInput,
} from "react-admin";
import Validator from "../Validator";
import { makeStyles } from "@material-ui/core/styles";
import { InputAdornment } from "@material-ui/core";
import PictureReferenceInput from "./PictureReferenceInput";

const ValidatorWithEntity = Validator.bind(null, "product");

export const styles = {
  fieldWithMargin: { width: "10em", marginLeft: 32 },
  fieldWithoutMargin: { width: "10em" },
  fieldWithoutMarginLong: { width: "15rem" },
  inlineTextWithoutMargin: { width: "15rem", display: "inline-block" },
};

const useStyles = makeStyles(styles);

const ProductEdit = (props) => {
  const classes = useStyles();

  return (
    <Edit {...props}>
      <TabbedForm validate={ValidatorWithEntity}>
        <FormTab label="info">
          <FormDataConsumer subscription={{ values: true }}>
            {({ formData }) =>
              formData.type === "single" && (
                <TextInput
                  label="Product ID"
                  source="code"
                  className={classes.fieldWithoutMargin}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer subscription={{ values: true }}>
            {({ formData, ...rest }) =>
              !formData.payment_on_delivery && (
                <div>
                  <NumberInput
                    label="Weight"
                    source="weight"
                    className={classes.fieldWithoutMargin}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">kg</InputAdornment>
                      ),
                    }}
                    required
                  />
                  <NumberInput
                    label="Price"
                    source="price"
                    className={classes.fieldWithMargin}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">€</InputAdornment>
                      ),
                    }}
                    required
                  />
                  <NumberInput
                    label="Discount price"
                    source="discount"
                    className={classes.fieldWithMargin}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">€</InputAdornment>
                      ),
                    }}
                    required
                  />
                </div>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer subscription={{ values: true }}>
            {({ formData, ...rest }) =>
              formData.payment_on_delivery && (
                <div>
                  <NumberInput
                    label="Weight from"
                    source="weight_from"
                    className={classes.fieldWithoutMargin}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">kg</InputAdornment>
                      ),
                    }}
                    required
                  />
                  <NumberInput
                    label="Weight to"
                    source="weight_to"
                    className={classes.fieldWithMargin}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">kg</InputAdornment>
                      ),
                    }}
                    required
                  />
                </div>
              )
            }
          </FormDataConsumer>
          <NumberInput
            label="Price per kilogram"
            source="kg_price"
            className={classes.fieldWithoutMargin}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">€/kg</InputAdornment>
              ),
            }}
            required
          />
          <AutocompleteInput
            source="status"
            className={classes.fieldWithoutMargin}
            choices={[
              { id: "published", name: "Published" },
              { id: "unpublished", name: "Unpublished" },
              { id: "soldout", name: "Soldout" },
            ]}
          />
        </FormTab>
        <FormTab label="translatable">
          <TextInput
            label="Description (English)"
            source="description_en"
            required
          />
          <TextInput label="Description (Estonian)" source="description_et" />
          <TextInput label="Description (Latvian)" source="description_lv" />
          <TextInput label="Description (Russian)" source="description_ru" />
          <TextInput label="Origin (English)" source="origin_en" />
          <TextInput label="Origin (Estonian)" source="origin_et" />
          <TextInput label="Origin (Latvian)" source="origin_lv" />
          <TextInput label="Origin (Russian)" source="origin_ru" />
          <TextInput label="Class (English)" source="class_en" />
          <TextInput label="Class (Estonian)" source="class_et" />
          <TextInput label="Class (Latvian)" source="class_lv" />
          <TextInput label="Class (Russian)" source="class_ru" />
        </FormTab>
        <FormTab label="details">
          <ReferenceInput
            label="Delivery region"
            source="region_id"
            reference="regions"
            perPage={1000}
            className={classes.fieldWithoutMarginLong}
            allowEmpty
          >
            <SelectInput optionText="name_en" />
          </ReferenceInput>
          <ReferenceInput
            label="Country"
            source="country_id"
            reference="countries"
            perPage={1000}
            className={classes.fieldWithoutMarginLong}
            required
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <NumberInput
            label="Boxes in crate"
            source="boxes"
            className={classes.fieldWithoutMarginLong}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">pc</InputAdornment>
              ),
            }}
          />
          <TextInput
            label="Kcal"
            source="kcal"
            className={classes.fieldWithoutMarginLong}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">count</InputAdornment>
              ),
            }}
          />
        </FormTab>
        <FormTab label="facts">
          <ArrayInput label="Fun Facts (English)" source="facts_en">
            <SimpleFormIterator>
              <TextInput label="Fun Fact" source="fact" />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput label="Fun Facts (Estonian)" source="facts_et">
            <SimpleFormIterator>
              <TextInput label="Fun Fact" source="fact" />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput label="Fun Facts (Latvian)" source="facts_lv">
            <SimpleFormIterator>
              <TextInput label="Fun Fact" source="fact" />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput label="Fun Facts (Russian)" source="facts_ru">
            <SimpleFormIterator>
              <TextInput label="Fun Fact" source="fact" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="desktop pictures">
          <PictureReferenceInput
            label="Primary desktop picture"
            source="primary_desktop_picture_id"
            reference="pictures"
            allowEmpty
            perPage={10000}
          />
          <ArrayInput
            label="Secondary Pictures"
            source="secondary_desktop_pictures"
          >
            <SimpleFormIterator>
              <PictureReferenceInput
                label="Desktop picture"
                source="picture_id"
                reference="pictures"
                allowEmpty
                perPage={10000}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="mobile pictures">
          <PictureReferenceInput
            label="Primary mobile picture"
            source="primary_mobile_picture_id"
            reference="pictures"
            allowEmpty
            perPage={10000}
          />
          <ArrayInput
            label="Secondary Pictures"
            source="secondary_mobile_pictures"
          >
            <SimpleFormIterator>
              <PictureReferenceInput
                label="Mobile picture"
                source="picture_id"
                reference="pictures"
                allowEmpty
                perPage={10000}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormDataConsumer subscription={{ values: true }}>
          {({ formData, ...rest }) =>
            formData.type === "combo" && (
              <FormTab label="recipe" {...rest}>
                <ArrayInput source="previous_combo_products" {...rest}>
                  <SimpleFormIterator disableAdd>
                    <FormDataConsumer>
                      {({ formData, scopedFormData, getSource, ...rest }) => (
                        <TextInput
                          disabled={true}
                          label="Product Name"
                          source={getSource("name")}
                        />
                      )}
                    </FormDataConsumer>
                    <TextInput label="Count" source="count" />
                  </SimpleFormIterator>
                </ArrayInput>
                <ArrayInput
                  label="Add new products"
                  source="new_combo_products"
                >
                  <SimpleFormIterator>
                    <SelectInput
                      label="Product"
                      source="product_id"
                      optionText="description"
                      optionValue="id"
                      choices={formData.all_products}
                    />
                    <TextInput label="Count" source="count" />
                  </SimpleFormIterator>
                </ArrayInput>
              </FormTab>
            )
          }
        </FormDataConsumer>
      </TabbedForm>
    </Edit>
  );
};
export default ProductEdit;
