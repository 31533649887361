import React, {
  useState,
  useEffect,
  useCallback,
  FC,
  CSSProperties,
} from "react"
import { useVersion, useDataProvider } from "react-admin"
import { useMediaQuery, Theme } from "@material-ui/core"
import { subDays } from "date-fns"
import auth from '../../providers/auth';

import NewOrders from "./NewOrders"
import CompletedOrders from "./CompletedOrders"
import MonhtlyRevenue from "./MonthlyRevenue"
import OrderGroupChart from "./OrderGroupChart"
import TopSalesArticlesList from "./TopSalesArticlesList"
import TopMissingRegionsList from "./TopMissingRegionsList"
import DateRangePicker from "./DateRangePicker"

import { OrderGroup, Order, TopSalesArticle, TopMissingRegion } from "../../types"

interface OrderStats {
  newOrders: number
  completedOrders: number
}

interface OrderGroupStats {
  revenue: number
}

interface DateSelection {
  startDate: Date
  endDate: Date
}

interface State {
  newOrders?: number
  completedOrders?: number
  revenue?: string
  recentOrderGroups?: OrderGroup[]
  topSalesArticles?: TopSalesArticle[]
  topMissingRegions?: TopMissingRegion[]
  dateSelection: DateSelection
}

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
}

const Spacer = () => <span style={{ width: "1em" }} />
const VerticalSpacer = () => <span style={{ height: "1em" }} />

const Dashboard: FC = () => {
  const initialDateSelection : DateSelection = {
    startDate: subDays(new Date(), 30),
    endDate: new Date()
  };

  const [state, setState] = useState<State>({
    dateSelection: initialDateSelection
  })

  const version = useVersion()
  const dataProvider = useDataProvider()

  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"))

  const fetchOrders = useCallback(async (permissionId) => {
    const { dateSelection } = state;

    const { data: recentOrders } = await dataProvider.getList<Order>("orders", {
      filter: {
         created_at_gte: dateSelection.startDate.toISOString(),
         created_at_lte: dateSelection.endDate.toISOString(),
         permission_id: permissionId
      },
      sort: { field: "created_at", order: "DESC" },
      pagination: { page: 1, perPage: 50 },
    })
    const aggregations = recentOrders.reduce(
      (stats: OrderStats, order) => {
        if (order.status === "DELIVERED") {
          stats.completedOrders++
        }
        if (order.status === "DISPATCHED") {
          stats.newOrders++
        }
        return stats
      },
      {
        newOrders: 0,
        completedOrders: 0,
      }
    );
    setState((state) => ({
      ...state,
      newOrders: aggregations.newOrders,
      completedOrders: aggregations.completedOrders,
    }))
  }, [dataProvider])

  const fetchOrderGroups = useCallback(async (permissionId) => {
    const { dateSelection } = state;

    const { data: recentOrderGroups } = await dataProvider.getList<OrderGroup>(
      "completed-order_groups",
      {
        filter: {
         created_at_gte: dateSelection.startDate.toISOString(),
         created_at_lte: dateSelection.endDate.toISOString(),
         permission_id: permissionId
      },
        sort: { field: "created_at", order: "DESC" },
        pagination: { page: 1, perPage: 50 },
      }
    )
    const aggregations = recentOrderGroups.reduce(
      (stats: OrderGroupStats, orderGroup) => {
        if (orderGroup.status === "COMPLETED") {
          stats.revenue += +orderGroup.price_total
        }
        return stats
      },
      {
        revenue: 0,
      }
    )
    setState((state) => ({
      ...state,
      recentOrderGroups,
      revenue: aggregations.revenue.toLocaleString(undefined, {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
    }))
  }, [dataProvider])

  const fetchTopSalesArticles = useCallback(async (permissionId) => {
    const { dateSelection } = state;

    const {data: topSalesArticles} = await dataProvider.getList<TopSalesArticle>(
      "top-sales-articles",
      {
        filter: {
          created_at_gte: dateSelection.startDate.toISOString(),
          created_at_lte: dateSelection.endDate.toISOString(),
          permission_id: permissionId
         },
        sort: {field: "created_at", order: "DESC"},
        pagination: {page: 1, perPage: 10},
      }
    )
    setState((state) => ({
      ...state,
      topSalesArticles: topSalesArticles,
    }))
  }, [dataProvider])

  const fetchTopMissingRegions = useCallback(async (permissionId) => {
    const { dateSelection } = state;

    const {data: topMissingRegions} = await dataProvider.getList<TopMissingRegion>(
      "top-missing-regions",
      {
        filter: {
         created_at_gte: dateSelection.startDate.toISOString(),
         created_at_lte: dateSelection.endDate.toISOString(),
         permission_id: permissionId
        },
        sort: {field: "created_at", order: "DESC"},
        pagination: {page: 1, perPage: 10},
      }
    )
    setState((state) => ({
      ...state,
      topMissingRegions: topMissingRegions,
    }))
  }, [dataProvider])

  const updateStateWithNewDate = (selection: DateSelection) => {
    setState((state) => ({
      ...state,
      dateSelection: selection
    }));
  }

  useEffect(() => {
    auth.getPermissions()
      .then((permissionId) => {
        fetchOrders(permissionId)
        fetchOrderGroups(permissionId)
        fetchTopSalesArticles(permissionId)
        fetchTopMissingRegions(permissionId)
      });
  }, [version, state.dateSelection.startDate, state.dateSelection.endDate]) // eslint-disable-line react-hooks/exhaustive-deps

  const { completedOrders, newOrders, revenue, recentOrderGroups, topSalesArticles, topMissingRegions, dateSelection } = state
  return isXSmall ? (
    <div>
      <div style={styles.flexColumn as CSSProperties}>
        <div style={{marginBottom: '2em'}}>
          <DateRangePicker dateSelection={dateSelection} dateSelectionChange={updateStateWithNewDate} />
        </div>
        <div style={styles.flex}>
          <NewOrders value={newOrders} />
          <VerticalSpacer />
          <CompletedOrders value={completedOrders} />
          <VerticalSpacer />
          <MonhtlyRevenue value={revenue} />
        </div>
      </div>
    </div>
  ) : isSmall ? (
    <div style={styles.flexColumn as CSSProperties}>
      <div style={styles.singleCol}>
        <DateRangePicker dateSelection={dateSelection} dateSelectionChange={updateStateWithNewDate}  />
      </div>
      <div style={styles.flex}>
        <NewOrders value={newOrders} />
        <Spacer />
        <CompletedOrders value={completedOrders} />
        <Spacer />
        <MonhtlyRevenue value={revenue} />
      </div>
      <div style={styles.singleCol}>
        <OrderGroupChart orderGroups={recentOrderGroups} dateSelection={dateSelection} />
      </div>
      <div style={styles.flex}>
        <TopSalesArticlesList topSalesArticles={topSalesArticles} />
        <Spacer />
        <TopMissingRegionsList topMissingRegions={topMissingRegions} />
      </div>
    </div>
  ) : (
    <>
      <div style={styles.flex}>
        <div style={styles.leftCol}>
        <div style={styles.singleCol}>
            <DateRangePicker dateSelection={dateSelection}  dateSelectionChange={updateStateWithNewDate} />
          </div>
          <div style={styles.flex}>
            <NewOrders value={newOrders} />
            <Spacer />
            <CompletedOrders value={completedOrders} />
            <Spacer />
            <MonhtlyRevenue value={revenue} />
          </div>
          <div style={styles.singleCol}>
            <OrderGroupChart orderGroups={recentOrderGroups} dateSelection={dateSelection} />
          </div>
          <div style={styles.flex}>
            <TopSalesArticlesList topSalesArticles={topSalesArticles} />
            <Spacer />
            <TopMissingRegionsList topMissingRegions={topMissingRegions} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard
