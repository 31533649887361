import React from "react";
import { Create, TextInput, RadioButtonGroupInput, ReferenceInput, SelectInput, TabbedForm, FormTab, NumberInput, BooleanInput } from "react-admin";
import Validator from "../Validator";
import { InputAdornment } from '@material-ui/core';

const typesArray = [
  {id: "müügipunkt", name: "Selling point"},
  {id: "iseteeninduslett", name: "Self service"}  
];

const ValidatorWithEntity = Validator.bind(null, 'sellingPoint');

const SellingPointsCreate = (props) => (
  <Create {...props}>
    <TabbedForm validate={ValidatorWithEntity}>
      <FormTab label="details">
        <TextInput label="Title" source="title" required />
        <TextInput label="Address" source="address" required />
        <RadioButtonGroupInput source="type" choices={typesArray} required />
        <BooleanInput label="Publish selling point" source="published" defaultValue={true}/>
        <NumberInput
          label="Latitude" 
          source="lat"                
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                lat
              </InputAdornment>
            )
          }}
          required 
        />
         <NumberInput
          label="Longitude" 
          source="lng"                
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                lng
              </InputAdornment>
            )
          }}
          required 
        />
        <ReferenceInput label="Country" source="country_id" reference="countries" required>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="City" source="region_id" reference="regions" perPage={1000} required>
          <SelectInput optionText="name_en" />
        </ReferenceInput>
      </FormTab>
      <FormTab label="translatable">
        <TextInput label="Opening (Estonian)" source="opening_et" />
        <TextInput label="Opening (English)" source="opening_en" />
        <TextInput label="Opening (Latvian)" source="opening_lv" />
        <TextInput label="Opening (Russian)" source="opening_ru" />
        <TextInput label="Info (Estonian)" source="info_et" />
        <TextInput label="Info (English)" source="info_en" />
        <TextInput label="Info (Latvian)" source="info_lv" />
        <TextInput label="Info (Russian)" source="info_ru" />
      </FormTab>
    </TabbedForm>
  </Create>
);

export default SellingPointsCreate;