import React from "react";
import { List, Datagrid, TextField, DateField, Pagination, Filter, TextInput} from "react-admin";
import FilterSidebar from '../filters/CountryStatusFilter';

const ReportPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />

const ReportFilter = (props) => (
  <Filter {...props}>
      <TextInput label="Address" source="address"/>
      <TextInput label="Client name" source="client_name"/>
      <TextInput label="Client phone" source="client_phone"/>
      <TextInput label="Delivered at" source="delivered_at"/>
      <TextInput label="Delivery begin" source="delivery_begin"/>
      <TextInput label="Delivery end" source="delivery_end"/>
      <TextInput label="Gift text" source="gift_text"/>
      <TextInput label="Order price" source="price"/>
      <TextInput label="Order description" source="description"/>
      <TextInput label="Payment method" source="payment_method"/>
      <TextInput label="Product code" source="code"/>
      <TextInput label="Product description (english)" source="description_en"/>
      <TextInput label="Invoice email" source="invoice_email"/> 
  </Filter>
);

const ReportsList = ({permissions, ...props}) => (
  <List {...props}
    bulkActionButtons={false}
    filter={{"permission_id": permissions}}
    filters={<ReportFilter />}
    aside={<FilterSidebar/>}
    pagination={<ReportPagination />}
    >
    <Datagrid>
      <DateField label="Order date" source="order_date" />
      <DateField label="Delivery date" source="delivery_date" />
      <TextField label="Product name" source="product_description" />
      <TextField label="Product count(pc)" source="sold_product_count" />
      <TextField label="Price total(w/ VAT)" source="price_with_vat" />
      <TextField label="Price total(w/o VAT)" source="price_without_vat" />
      <TextField label="Payment status" source="payment_status" />
      <TextField label="Delivery status" source="delivery_status" />
    </Datagrid>
  </List>
);

export default ReportsList;
