import * as React from 'react'
import { FC } from 'react'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'

import CardWithIcon from './CardWithIcon'

interface Props {
    value?: number
}

const completedOrders: FC<Props> = ({ value }) => {
    return (
        <CardWithIcon
            to="/reports"
            icon={ShoppingCartIcon}
            title="Completed orders"
            subtitle={value}
        />
    )
}

export default completedOrders