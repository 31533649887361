import React from "react";
import { List, Datagrid, TextField, DateField, Pagination} from "react-admin";
import FilterSidebar from '../filters/CountryFilter';

const MissingRegionsPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />


const MissingRegionsList = ({permissions, ...props}) => (
  <List {...props}
  bulkActionButtons={false}
  aside={<FilterSidebar/>}
  filter={{"permission_id": permissions}}
  pagination={<MissingRegionsPagination />}
  >
    <Datagrid>
      <DateField label="Created At" source="created_at" />
      <TextField label="Address" source="address" />
      <TextField label="Postal code" source="postal_code" />
      <TextField label="Country" source="country" />
    </Datagrid>
  </List>
);

export default MissingRegionsList;
