import { Admin, Resource } from "react-admin";
import dataProviderExt from "./providers/dataProviderExt";
import i18nProvider from "./providers/i18nProvider";
import auth from "./providers/auth";
import MyLayout from "./components/Layout";

import pictures from './components/Picture';
import products from './components/Product';
import users from './components/User';
import regions from './components/Region';
import sellingPoints from './components/SellingPoint';
import invoices from './components/Invoice';
import messages from './components/Message';
import reports from './components/Report';
import { dashboard } from './components/Dashboard';
import missingRegions from './components/MissingRegion';

import customRoutes from './customRoutes';

const App = () => (
  <Admin 
    layout={MyLayout} 
    authProvider={auth} 
    dataProvider={dataProviderExt} 
    i18nProvider={i18nProvider}
    dashboard={dashboard}
    customRoutes={customRoutes}
  >
    <Resource name="dashboard" {...dashboard} />
    <Resource name="products" {...products} />    
    <Resource name="pictures" {...pictures} />
    <Resource name="users" {...users} />
    <Resource name="regions" {...regions} />
    <Resource name="selling_points" {...sellingPoints} />
    <Resource name="invoices" {...invoices} />
    <Resource name="reports" {...reports} />
    <Resource name="notification_messages" {...messages} /> 
    <Resource name="missing_regions" {...missingRegions} />

    {/* empty countries resource is needed for country reference fields */}
    <Resource name="countries" />
  </Admin>
);

export default App;
