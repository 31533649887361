import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  ImageField,
} from "react-admin";

const InvoicesShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
        <TextField label="Picture" source="file_name" />
        <ImageField source="picture.src"/>
        </SimpleShowLayout>
  </Show>
);

export default InvoicesShow;