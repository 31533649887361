import React from "react";
import { List, Datagrid, TextField, ShowButton, Pagination } from "react-admin";

const PicturePagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />

const PicturesList = (props) => (
  <List 
    {...props}
    pagination={<PicturePagination />}
  >
    <Datagrid>
      <TextField label="Picture" source="file_name" />
      <TextField label="Product usage count" source="product_count" />
      <ShowButton />
    </Datagrid>
  </List>
);

export default PicturesList;