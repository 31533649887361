import React from "react";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  Datagrid,
  ArrayField,
} from "react-admin";

const InvoicesShow = (props) => (
  <Show {...props}>
    <TabbedShowLayout syncWithLocation={false}>
      <Tab label="invoice">
        <TextField label="Invoice id" source="id" />
        <DateField label="Created at" source="created_at" />
        <TextField label="Payment status" source="status" />
        <TextField label="Payment method" source="payment_method" />
        <TextField label="Price" source="price_total" />
      </Tab>
      <Tab label="orders">
        <ArrayField source="orders">
          <Datagrid>
            <TextField label="Order id" source="id" />
            <DateField label="Created" source="created_at" />
            <DateField label="Delivered" source="delivered_at" />
            <TextField label="Description" source="description" />
            <TextField label="Client name" source="client_name" />
            <TextField label="Client phone" source="client_phone" />
            <TextField label="Address" source="address" />
            <TextField label="Price (with delivery fee)" source="price" />
            <TextField label="Delivery" source="delivery" />
            <TextField label="Delivery status" source="status" />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label="Products">
        <ArrayField source="products">
          <Datagrid>
            <TextField label="Order Id" source="order_id" />
            <TextField label="Product name" source="description" />
            <TextField label="Product price" source="product_price" />
            <TextField label="Product count for order" source="count" />
            <TextField label="Total price" source="product_order_price" />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default InvoicesShow;
