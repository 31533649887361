import * as React from 'react';
import { FC } from 'react';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

import CardWithIcon from './CardWithIcon';

interface Props {
    value?: number;
}

const newOrders: FC<Props> = ({ value }) => {
    return (
        <CardWithIcon
            to="/reports"
            icon={AddShoppingCartIcon}
            title="Dispatched orders"
            subtitle={value}
        />
    );
};

export default newOrders;