import * as React from "react";
import {
    FilterList, FilterListItem
} from 'react-admin';

import LanguageIcon from '@material-ui/icons/Language';
import PaymentIcon from '@material-ui/icons/Payment';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

export const CountryFilterList = () =>  (
    <FilterList 
        label="Country"
        icon={<LanguageIcon />}
    >
        <FilterListItem
            label="Estonia"
            value={{country_id: 1}}
        />
        <FilterListItem
            label="Latvia"
            value={{country_id: 2}}
        />
        <FilterListItem
            label="Lithuania"
            value={{country_id: 3}}
        />
    </FilterList>
);

export const PaymentStatusFilterList = () => (
    <FilterList 
        label="Payment status"
        icon={<PaymentIcon />}
    >
        <FilterListItem
            label="Created"
            value={{payment_status: 'CREATED'}}
        />
        <FilterListItem
            label="Pending"
            value={{payment_status: 'PENDING'}}
        />
        <FilterListItem
            label="Completed"
            value={{payment_status: 'COMPLETED'}}
        />
    </FilterList>
);


export const DeliveryStatusFilterList = () => (
    <FilterList 
        label="Delivery status"
        icon={<LocalShippingIcon />}
    >
        <FilterListItem
            label="Created"
            value={{delivery_status: 'CREATED'}}
        />
        <FilterListItem
            label="Dispatched"
            value={{delivery_status: 'DISPATCHED'}}
        />
        <FilterListItem
            label="Failed"
            value={{delivery_status: 'FAILED'}}
        />
        <FilterListItem
            label="Delivered"
            value={{delivery_status: 'DELIVERED'}}
        />
    </FilterList>
);