import React from "react";
import { Create, TextInput, SimpleForm, ImageInput, ImageField } from "react-admin";
import Validator from "../Validator";
const ValidatorWithEntity = Validator.bind(null, 'picture');

const PictureCreate = (props) => (
  <Create {...props}>
    <SimpleForm validate={ValidatorWithEntity}>
      <TextInput label="Picture name" source="name" />
        <ImageInput
            multiple={false}
            source="picture"
            label="picture"
            accept="image/*"
        >
        <ImageField source="src" title="title" />
        </ImageInput>
    </SimpleForm>
  </Create>
);

export default PictureCreate;