import { fetchUtils } from "react-admin";

import jsonServerProvider from "ra-data-json-server";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  if (localStorage.getItem("token")) {
    const { access_token } = JSON.parse(localStorage.getItem("token"));
    options.headers.set("Authorization", `Bearer ${access_token}`);
  }
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(  
  process.env.REACT_APP_API_PATH,
  httpClient
);

const dataProviderLocale = jsonServerProvider(
  process.env.REACT_APP_LOCALE_API_PATH,
  httpClient
);

const dataProviderExt = {
  ...dataProvider,
  create: (resource, params) => {
    // Fallback to default for other resources
    if (resource !== "pictures") {
      return dataProvider.create(resource, params);
    } else {
      return createPicture(resource, params);
    }
  },

  getList: (resource, params) => {
    if (resource === "products" || resource === "regions" || resource === "selling_points") {
      return dataProviderLocale.getList(resource, params);
    } else {
      return dataProvider.getList(resource, params);
    }
  },

  getOne: (resource, params) => {
    if (resource === "products" || resource === "regions" || resource === "selling_points") {
      return dataProviderLocale.getOne(resource, params);
    } else {
      return dataProvider.getOne(resource, params);
    }
  },

  getMany: (resource, params) => {
    if (resource === "products" || resource === "regions" || resource === "selling_points") {
      return dataProviderLocale.getMany(resource, params);
    } else {
      return dataProvider.getMany(resource, params);
    }
  },

  // Custom create many implementation for csv import
  createMany: (resource, params) => {
    if (resource === 'invoices') {
      return httpClient(process.env.REACT_APP_API_PATH + "/invoices/create_many", {
        headers: new Headers({
           Accept: 'application/json'
          }),
        method: 'POST',
        body: JSON.stringify(params.data)
      })
      .then(response => {
        return JSON.parse(response.body);
      })
      .then(resJson => {
        return {data: resJson};
      })
    }
  }
};


const createPicture = (resource, params) =>  {
  const picture = params.data.picture;

  return convertPictureToBase64(picture)
  .then((conversion) => 
    dataProvider.create(resource, {
      ...params,
      data: {
        ...params.data,
        picture: conversion
      }
    })
  );
};

const convertPictureToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () =>
      resolve({ base64Conversion: reader.result, title: file.title });
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });

export default dataProviderExt;
