import React from "react";
import { List, Datagrid, TextField, EditButton, Pagination, BulkDeleteButton } from "react-admin";
import { Fragment } from 'react';
import FilterSidebar from '../filters/CountryFilter';
import IncludedRegionsCreationButton from './IncludedRegionsCreationButton';

const RegionPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />

const PostBulkActionButtons = props => (
  <Fragment>
      <IncludedRegionsCreationButton label="Create combined region" {...props} />
      {/* default bulk delete action */}
      <BulkDeleteButton {...props} />
  </Fragment>
);

const RegionsList = ({permissions, ...props}) => (
  <List 
    {...props}
    aside={<FilterSidebar/>}
    filter={{"permission_id": permissions}}
    pagination={<RegionPagination />}
    bulkActionButtons={<PostBulkActionButtons />}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="country" />
      <TextField label="Selling point usage count" source="selling_point_count" />
      <TextField label="Product usage count" source="product_count" />
      <EditButton />
    </Datagrid>
  </List>
);

export default RegionsList;
