import React from "react";
import { Fragment } from 'react';
import ComboProductCreationButton from './ComboProductCreationButton';
import FilterSidebar from '../filters/CountryFilter';
import { List, Datagrid, TextField, EditButton, BulkDeleteButton, Pagination} from 'react-admin';

const PostBulkActionButtons = props => (
    <Fragment>
        <ComboProductCreationButton label="ComboProductCreationButton" {...props} />
        {/* default bulk delete action */}
        <BulkDeleteButton {...props} />
    </Fragment>
);

const ProductPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />

const ProductsList = ({permissions, ...props}) => (
    <List 
        {...props} 
        bulkActionButtons={<PostBulkActionButtons />}
        aside={<FilterSidebar/>}
        filter={{"permission_id": permissions}}
        pagination={<ProductPagination />}
    >
        <Datagrid>
            <TextField source="code" />
            <TextField source="description" />
            <TextField source="weight" />
            <TextField source="price" />
            <TextField source="type" />
            <TextField label="Price per kg" source="kg_price" />
            <TextField source="status" />
            <TextField source="country" />
            <EditButton />
        </Datagrid>
    </List>
);

export default ProductsList;