import * as React from 'react';
import { FC } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import CardWithIcon from './CardWithIcon';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { TopSalesArticle } from '../../types';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';

interface Props {
    topSalesArticles?: TopSalesArticle[];
}

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
    },
    cost: {
        marginRight: '1em',
        color: theme.palette.text.primary,
    },
    flexEnd: {
        'display': 'flex',
        'flex-direction': 'column',
        'width': '100%'
    }
}))

const TopSalesArticles: FC<Props> = ({ topSalesArticles = [], }) => {
    const classes = useStyles()   
    return (
        <CardWithIcon
            to="/products"
            icon={ShoppingBasketIcon}           
            subtitle="Sold products"
        >
            <List dense={true}>
                {topSalesArticles.map(record => (
                    <ListItem
                        key={record.id}
                        button
                        component={Link}
                        to={`/products/${record.id}`}
                    >
                        <ListItemAvatar>
                            <Avatar
                                src={`${
                                    record.picture
                                }?size=32x32`}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={record.name}
                            secondary={record.country}
                        />
                         <ListItemSecondaryAction>
                            <span className={classes.cost}>
                                {record.sales_price_net ? record.sales_price_net : 0}€
                            </span>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </CardWithIcon>
    );
};

export default TopSalesArticles