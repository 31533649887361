import RequiredValidator from "./RequiredValidator";
import CustomValidator from "./CustomValidator";

const requiredValidatorEntities = [
  "user",
  "sellingPoint",
  "region",
  "product",
  "picture",
  "notificationMessage",
];

interface Custom {
  isValid: any;
}

interface Required {
  isRequired: boolean;
}

interface KeyValidators {
  required: Required;
  custom: Custom | null;
}

interface FieldValidators {
  [key: string]: KeyValidators;
}

const Validations = (entity: string, paymentOnDelivery?: boolean) => {
  const fieldValidators: FieldValidators = {};

  if (requiredValidatorEntities.includes(entity)) {
    RequiredValidator(entity, fieldValidators, paymentOnDelivery);
  }

  if (entity === "notificationMessage") {
    CustomValidator(entity, fieldValidators);
  }

  return fieldValidators;
};

export default Validations;
