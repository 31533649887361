import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { MenuItemLink, DashboardMenuItem } from "react-admin";

import StoreIcon from '@material-ui/icons/Store';
import ReceiptIcon from '@material-ui/icons/Receipt';
import LocationCity from "@material-ui/icons/LocationCity";
import People from "@material-ui/icons/People";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import MessageIcon from '@material-ui/icons/Message';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';

const Menu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  
  return (
    <div>
      <DashboardMenuItem
        leftIcon={<DashboardIcon/>}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        key='products'
        to={`/products`}
        primaryText='Products'
        leftIcon={<ShoppingBasketIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to={`/ProductStoreOrder/StoreOrder`}
        primaryText='Product store order'
        leftIcon={<FormatListNumberedIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to={`/ProductTaskOrder/TaskOrder`}
        primaryText='Product packing order'
        leftIcon={<FormatListNumberedIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to="/pictures"
        primaryText="Pictures"
        leftIcon={<PhotoLibraryIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to="/users"
        primaryText="Users"
        leftIcon={<People />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to="/regions"
        primaryText="Regions"
        leftIcon={<LocationCity />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to="/selling_points"
        primaryText="Selling Points"
        leftIcon={<StoreIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to="/invoices"
        primaryText="Orders"
        leftIcon={<ReceiptIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to="/reports"
        primaryText="Reports"
        leftIcon={<ShoppingCartIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to="/notification_messages"
        primaryText="SMS Messages"
        leftIcon={<MessageIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to='/missing_regions'
        primaryText="Missing Location"
        leftIcon={<NotListedLocationIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {isXSmall && logout}
    </div>
  );
};

export default Menu;
