import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  ImageInput, 
  ImageField,
} from "react-admin";
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

function PictureQuickCreateButton({ onChange }) {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('pictures');
  const notify = useNotify();
  const form = useForm();

  const handleClick = () => {
      setShowDialog(true);
  };

  const handleCloseClick = () => {
      setShowDialog(false);
  };

  const handleSubmit = async values => {
      create(
          { payload: { data: values } },
          {
              onSuccess: ({ data }) => {
                  setShowDialog(false);
                  // Update the comment form to target the newly created post
                  // Updating the ReferenceInput value will force it to reload the available posts
                  form.change('picture_id', data.id);
                  onChange();
              },
              onFailure: ({ error }) => {
                  notify(error.message, 'error');
              }
          }
      );
  };

  return (
      <>
          <Button onClick={handleClick} label="Create">
              <IconContentAdd />
          </Button>
          <Dialog
              fullWidth
              open={showDialog}
              onClose={handleCloseClick}
              aria-label="Create picture"
          >
              <DialogTitle>Create picture</DialogTitle>

              <FormWithRedirect
                  resource="pictures"
                  save={handleSubmit}
                  render={({
                      handleSubmitWithRedirect,
                      pristine,
                      saving
                  }) => (
                      <>
                          <DialogContent>
                          <TextInput
                           label="Picture name" 
                           source="name"
                           fullWidth
                          />
                          <ImageInput
                            multiple={false}
                            source="picture"
                            label="picture"
                            fullWidth
                            accept="image/*"
                            >
                          <ImageField source="src" title="title" />  
                          </ImageInput> 
                          </DialogContent>
                          <DialogActions>
                              <Button
                                  label="cancel"
                                  onClick={handleCloseClick}
                                  disabled={loading}
                              >
                                  <IconCancel />
                              </Button>
                              <SaveButton
                                  handleSubmitWithRedirect={
                                      handleSubmitWithRedirect
                                  }
                                  pristine={pristine}
                                  saving={saving}
                                  disabled={loading}
                              />
                          </DialogActions>
                      </>
                  )}
              />
          </Dialog>
      </>
  );
}

export default PictureQuickCreateButton;